import React from "react"

const Link = ({ href, children }) => {
  return (
    <a style={{ margin: "1rem 0" }} href={href}>
      {children}
    </a>
  )
}

export default Link
